import { Typography } from "@material-ui/core"
import ButtonBase from "@material-ui/core/ButtonBase"
import { animated } from "@react-spring/web"
import { Link } from "gatsby"
import React, { useMemo } from "react"
import styled from "styled-components"
import useBoop from "../../../hooks/useBoop"
import { shadow, simpleTransition } from "../../../theme/globalStyles"

type Color = "blue" | "yellow" | "purple" | string
interface IProps {
  color: Color
  link?: Maybe<string>
  submit?: boolean
  disabled?: boolean
  onClick?: any
}

const Base = styled(ButtonBase)<{ color: Color; disabled?: boolean }>`
  background-color: ${({ color, disabled, theme }) => {
    switch (color) {
      case "purple":
        return disabled ? theme.palette.secondary.light : theme.palette.secondary.main
      case "yellow":
        return disabled ? theme.palette.tertiary.light : theme.palette.tertiary.main
      case "blue":
      default:
        return disabled ? theme.palette.primary.light : theme.palette.primary.main
    }
  }};
  color: ${({ color, disabled, theme }) => {
    switch (color) {
      case "purple":
        return disabled ? theme.palette.grey[600] : theme.palette.secondary.contrastText
      case "yellow":
        return disabled ? theme.palette.grey[600] : theme.palette.tertiary.contrastText
      case "blue":
      default:
        return disabled ? theme.palette.grey[600] : theme.palette.primary.contrastText
    }
  }};

  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(4)}px`};
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  ${shadow};
  ${simpleTransition};

  :hover {
    background-color: ${({ color, disabled, theme }) => {
      switch (color) {
        case "purple":
          return disabled ? theme.palette.secondary.light : theme.palette.secondary.dark
        case "yellow":
          return disabled ? theme.palette.tertiary.light : theme.palette.tertiary.dark
        case "blue":
        default:
          return disabled ? theme.palette.primary.light : theme.palette.primary.dark
      }
    }};
  }
`

const Label = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`

const Button: React.FC<IProps> = ({ children, color, link, submit, disabled, onClick }) => {
  const [style, trigger] = useBoop({ x: 6 })

  const setLink = disabled ? false : link

  const Wrapper: React.FC = useMemo(
    () =>
      ({ children }) =>
        setLink ? (
          <Link to={String(setLink)} style={{ textDecoration: "none" }} onMouseEnter={trigger}>
            {children}
          </Link>
        ) : (
          <div onMouseEnter={trigger}>{children}</div>
        ),
    [setLink]
  )

  return (
    <Wrapper>
      <Base
        color={color}
        type={submit ? "submit" : "button"}
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
      >
        <animated.span style={!disabled ? style : {}}>
          <Typography variant="button">{`>>`}</Typography>
        </animated.span>
        <Label variant="button">{children}</Label>
      </Base>
    </Wrapper>
  )
}

export default Button
