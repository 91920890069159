import IconButton from "@material-ui/core/IconButton"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import CloseIcon from "@material-ui/icons/Close"
import { Link as GatsbyLink } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import useCurrency from "../../hooks/useCurrency"
import { simpleTransition, xsMediaQuery } from "../../theme/globalStyles"
import { ProductFragment } from "../../__generated__/graphql-gatsby"
import BounceDown from "../base/bounceDown"
import { useFavContext } from "../context/favContext"

interface IProps {
  product: ProductFragment
}

const Link = styled(GatsbyLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.dark};
  font-weight: 900;
  font-size: 140%;
  letter-spacing: 0.18rem;
  ${simpleTransition}

  :hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  ${xsMediaQuery(css`
    font-size: 120%;
  `)}
`

export const ImgCell = styled(TableCell)`
  width: 150px;
  position: relative;
`

export const PriceCell = styled(TableCell)`
  font-weight: 500;
  font-size: 115%;
  padding-right: ${({ theme }) => theme.spacing(2.5)}px;
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 10;
  transform: translate(0%, -25%);
  background-color: rgba(224, 224, 224, 0.33);
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  ${simpleTransition};
  :hover {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`

const ProductRow: React.FC<IProps> = ({ product }) => {
  const {
    favorites: { favorites },
    dispatch,
    ACTIONS,
  } = useFavContext()
  const currency = useCurrency()

  const { uri, title, product_fields } = product
  const { afbeeldingen, prijs } = { ...product_fields }
  const afbeelding = afbeeldingen && afbeeldingen[0]
  const img = afbeelding?.afbeelding?.localFile?.childImageSharp?.gatsbyImageData
  const alt = afbeelding?.afbeelding?.altText

  const storage = favorites.find(item => item.id === product.id)
  const amount = storage ? storage.amount : 1

  const remove = () => dispatch({ type: ACTIONS.REMOVE, id: product.id })
  const increment = () => dispatch({ type: ACTIONS.INCREMENT, id: product.id })
  const decrement = () => dispatch({ type: ACTIONS.DECREMENT, id: product.id })

  return (
    <TableRow>
      <ImgCell className="hideSm">
        <CloseButton onClick={remove}>
          <CloseIcon />
        </CloseButton>
        <GatsbyImage image={img} alt={String(alt)} />
      </ImgCell>
      <TableCell>
        <Link to={String(uri)}>
          <BounceDown>{title}</BounceDown>
        </Link>
      </TableCell>
      <TableCell align="right" className="amount">
        <IconButton size="small" edge="start" onClick={decrement} disabled={amount === 1}>
          <ChevronLeftIcon />
        </IconButton>
        {amount}
        <IconButton size="small" edge="end" onClick={increment} disabled={amount === 200}>
          <ChevronRightIcon />
        </IconButton>
      </TableCell>
      <PriceCell align="right" className="hideSm">
        {currency(String(prijs)).format()}
      </PriceCell>
      <PriceCell align="right">{currency((prijs || 0) * amount).format()}</PriceCell>
    </TableRow>
  )
}

export default ProductRow
