import Container from "@material-ui/core/Container"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import { graphql, PageProps } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import Button from "../../components/base/button"
import { useFavContext } from "../../components/context/favContext"
import ProductRow, { ImgCell, PriceCell } from "../../components/productRow"
import SEO from "../../components/SEO"
import useCurrency from "../../hooks/useCurrency"
import { xsMediaQuery } from "../../theme/globalStyles"
import { FavorietenPageQuery } from "../../__generated__/graphql-gatsby"

type IProps = PageProps<FavorietenPageQuery>

type PriceRowProps = {
  label: string
  price: number
  addPlus?: boolean
  bold?: boolean
  small?: boolean
}

const TableWrapper = styled(TableContainer)`
  margin: ${({ theme }) => `${theme.spacing(4)}px 0 ${theme.spacing(6)}px`};

  .MuiTableBody-root {
    .MuiTableCell-root {
      padding-top: ${({ theme }) => theme.spacing(4)}px;
      border: none;
    }
  }

  .hideSm {
    ${xsMediaQuery(css`
      display: none !important;
    `)}
  }

  .amount {
    padding-left: 0;
    padding-right: 0;
    min-width: 70px;
  }
`

const HeaderCell = styled(TableCell)`
  font-weight: 700;
  font-size: 115%;
  letter-spacing: 0.125rem;
`

const TotalCell = styled(PriceCell)`
  border-top: 1px solid ${({ theme }) => theme.palette.secondary.light} !important;
  position: relative;

  ::after {
    content: "+";
    font-weight: 600;
    position: absolute;
    color: ${({ theme }) => theme.palette.secondary.light};
    top: ${({ theme }) => `-${theme.spacing(3)}px`};
    right: ${({ theme }) => `${theme.spacing(0.5)}px`};
  }
`

const DefaultRow = styled(TableRow)`
  .bold {
    font-weight: 900;
  }
`

const SmallRow = styled(DefaultRow)`
  > td {
    padding-top: 0 !important;
  }
`

const BtnWrapper = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  justify-content: flex-end;
`

const PriceRow: React.FC<PriceRowProps> = ({ label, price, addPlus, bold, small }) => {
  const Cell = addPlus ? TotalCell : PriceCell
  const Row = small ? SmallRow : DefaultRow
  const className = bold ? `bold` : ``
  const currency = useCurrency()

  return (
    <Row>
      <TableCell className="hideSm" />
      <TableCell />
      <TableCell className="hideSm" />
      <PriceCell align="right" className={className}>
        {label}:
      </PriceCell>
      <Cell align="right" className={className}>
        {currency(price).format()}
      </Cell>
    </Row>
  )
}

const FavorietenPage: React.FC<IProps> = ({ data: { allWpProduct } }) => {
  const {
    favorites: { favorites, amount },
  } = useFavContext()

  const renderList = allWpProduct.nodes.filter(productNode =>
    favorites.map(f => f.id).includes(productNode.id)
  )

  const total = renderList.reduce(
    (acc, product) =>
      acc +
      Number(
        (product.product_fields?.prijs || 0) *
          (favorites.find(f => f.id === product.id)?.amount || 1)
      ),
    0
  )

  const btw = total * 0.21

  const title = "Je Favoriete PUP producten"
  const desc = "Vraag een offerte aan van al jouw favorieten producten van Pop Up Pallets"

  return (
    <>
      <SEO
        title={title}
        description={desc}
        ogTitle={title}
        ogDescription={desc}
        canonical="/favorieten/"
      />
      <Container maxWidth="lg">
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <ImgCell className="hideSm" />
                <HeaderCell>Product</HeaderCell>
                <HeaderCell align="right" className="amount">
                  Aantal
                </HeaderCell>
                <HeaderCell align="right" className="hideSm">
                  Prijs p/s
                </HeaderCell>
                <HeaderCell align="right">Subtotaal</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderList.map(product => (
                <ProductRow key={product.id} product={product} />
              ))}
              <PriceRow label="subtotaal" price={total} addPlus={amount !== 0} />
              <PriceRow label="btw (21%)" price={btw} small />
              <PriceRow label="totaal" price={btw + total} addPlus bold />
            </TableBody>
          </Table>
        </TableWrapper>
        <BtnWrapper>
          <Button color="blue" link="/favorieten/offerte/" disabled={amount === 0}>
            Vraag offerte aan
          </Button>
        </BtnWrapper>
        <Typography
          variant="caption"
          component="span"
          style={{ width: "100%", display: "block", textAlign: "right", marginBottom: 32 }}
        >
          * Prijs is ex transport- en opbouwkosten, per dag
        </Typography>
      </Container>
    </>
  )
}

export default FavorietenPage

export const query = graphql`
  query FavorietenPage {
    allWpProduct {
      nodes {
        ...Product
      }
    }
  }
`
